import { Route } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';
import { useTranslation } from 'react-i18next';
import { Logo } from '@/common/components/logo';
import { useAuth } from '@/hooks';
import { Subtitle, Title } from '../typography';
import { GoogleIcon, PasswordIcon, PhoneIcon } from '../icons';
import { LanguageSwitcher } from '../language-switcher';
import styles from './styles.module.scss';
import { LoginByOTPScreen } from './components/login-by-otp-screen';
import { LoginByPasswordScreen } from './components/login-by-password-screen';
import ForgetPasswordScreen from './components/forget-password-screen';
import { useLoginNavigate } from './hooks';
import { LOGIN_ROUTES } from './constants';

type LoginOptions = {
  allowRegister: boolean;
  allowLoginInByOTP: boolean;
  allowLoginByPassword: boolean;
  allowLoginBySocial: boolean;
};

type Props = {
  options: LoginOptions;
  onLoginSuccess: VoidFunction;
};
const LoginSelection = ({ options }: { options: LoginOptions }) => {
  const { t } = useTranslation('common');
  const { goToGoogleLogin } = useAuth();
  const navigate = useLoginNavigate();

  return (
    <div>
      <Subtitle className={styles.intro}>{t('loginOptionsScreen.title')}</Subtitle>
      <div className={styles.buttonGroup}>
        {options.allowLoginInByOTP && (
          <div
            className={styles.loginButton}
            role="button"
            onClick={() => {
              navigate(LOGIN_ROUTES.OTP_SCREEN);
            }}
          >
            <div className={styles.icon}>
              <PhoneIcon />
            </div>
            <div className={styles.text}>{t('loginOptionsScreen.loginWithOneTimeCode')}</div>
          </div>
        )}

        {options.allowLoginByPassword && (
          <div
            className={styles.loginButton}
            role="button"
            onClick={() => {
              navigate(LOGIN_ROUTES.PASSWORD_SCREEN);
            }}
          >
            <div className={styles.icon}>
              <PasswordIcon />
            </div>
            <div className={styles.text}>{t('loginOptionsScreen.loginWithPassword')}</div>
          </div>
        )}

        {options.allowLoginBySocial && (
          <div
            className={styles.loginButton}
            role="button"
            onClick={() => {
              goToGoogleLogin();
            }}
          >
            <div className={styles.icon}>
              <GoogleIcon />
            </div>
            <div className={styles.text}>{t('loginOptionsScreen.loginWithGoogle')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export function Login({ options, onLoginSuccess }: Props) {
  const { t } = useTranslation('common');
  const navigate = useLoginNavigate();
  const goToForgetPasswordScreen = () => {
    navigate(LOGIN_ROUTES.FORGET_PASSWORD_SCREEN);
  };

  const goBack = (props: { fromResetPasswordSuccess?: boolean }) => {
    if (props.fromResetPasswordSuccess) {
      navigate(LOGIN_ROUTES.PASSWORD_SCREEN, { resetPassword: 'true' });
    } else {
      navigate(LOGIN_ROUTES.PASSWORD_SCREEN);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.content}>
          <Title size="x-large" className={styles.title}>
            {t('header.title')}
          </Title>
        </div>
        <div className={styles.languageSwitcher}>
          <LanguageSwitcher />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.content}>
          <SlideRoutes duration={400}>
            <Route path="/" element={<LoginSelection options={options} />} />
            <Route path="/otp/*" element={<LoginByOTPScreen onLoginSuccess={onLoginSuccess} />} />
            <Route
              path="/password"
              element={
                <LoginByPasswordScreen
                  goToForgetPasswordScreen={goToForgetPasswordScreen}
                  onLoginSuccess={onLoginSuccess}
                />
              }
            />
            <Route path="/forget-password/*" element={<ForgetPasswordScreen goBack={goBack} />} />
          </SlideRoutes>
        </div>
      </div>
    </div>
  );
}
