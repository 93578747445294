import i18next, { changeLanguage } from 'i18next';
import { useState } from 'react';
import EnglandFlag from '@/images/flag_en.png';
import SwedenFlag from '@/images/flag_sv.png';
import { setCookie } from '@/common/utils';
import styles from './styles.module.scss';

const LANGUAGES = {
  SWEDISH: 'sv',
  ENGLISH: 'en',
};

export function LanguageSwitcher() {
  const [currentLanguage, setCurrentLanguage] = useState(
    i18next.resolvedLanguage || LANGUAGES.SWEDISH
  );
  const isSwedish = currentLanguage === LANGUAGES.SWEDISH;

  return (
    <div
      className={styles.wrapper}
      role="banner"
      onClick={() => {
        const nextLanguage = isSwedish ? LANGUAGES.ENGLISH : LANGUAGES.SWEDISH;
        setCookie('i18next', nextLanguage);
        setCurrentLanguage(nextLanguage);
        changeLanguage(nextLanguage);
      }}
    >
      <img src={isSwedish ? EnglandFlag : SwedenFlag} alt="" />
    </div>
  );
}
