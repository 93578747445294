import { useTranslation } from 'react-i18next';
import { checkPassword } from '@/common/utils/string';
import { CheckedIcon } from '../icons';
import styles from './styles.module.scss';

export function PasswordStrength({ value }: { value: string }) {
  const { t } = useTranslation('common');
  const rules = checkPassword(value);

  const isError = Object.keys(rules).some((k) => !rules[k as keyof typeof rules]);

  if (!isError) {
    return null;
  } else {
    return (
      <div className={styles.container}>
        {Object.keys(rules).map((k) => {
          const isInvalid = !rules[k as keyof typeof rules];
          const text = t(`form.${k}`);

          if (isInvalid) {
            return (
              <div className={styles.invalidRule}>
                <div className={styles.iconBlock} />
                <div className={styles.textBlock}>{text}</div>
              </div>
            );
          }

          return (
            <div className={styles.validRule}>
              <div className={styles.iconBlock}>
                <CheckedIcon />
              </div>
              <div className={styles.textBlock}>{text}</div>
            </div>
          );
        })}
      </div>
    );
  }
}
