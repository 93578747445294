import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks';
import { ArrowLeftIcon } from '@/common/components/icons';
import { Title, Subtitle } from '@/common/components/typography';
import { Summary } from '@/common/components';
import { isTouchDevice } from '@/common/utils';
import { LOGIN_ERROR_CODE } from '@/constants';
import { LOGIN_ROUTES } from '../../constants';
import { useLoginNavigate } from '../../hooks';
import styles from './styles.module.scss';

type Params = {
  email: string;
  password: string;
};

type Props = {
  onLoginSuccess: VoidFunction;
  goToForgetPasswordScreen: VoidFunction;
};

export function LoginByPasswordScreen({ goToForgetPasswordScreen, onLoginSuccess }: Props) {
  const { t } = useTranslation('common');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useLoginNavigate();
  const { signInByPassword } = useAuth();
  const [form] = Form.useForm<Params>();
  const loginAction = useMutation<any, { errorCode: string }, any, any>(
    (values: Params) => {
      return signInByPassword(values.email, values.password);
    },
    { onSuccess: onLoginSuccess }
  );

  const onFinish = (values: Params) => {
    loginAction.mutate(values);
  };

  const accountIsDisabled =
    loginAction.error && loginAction.error?.errorCode === LOGIN_ERROR_CODE.USER_IS_DISABLED;

  return (
    <div>
      <Title className={styles.loginTitle} bold>
        {t('loginWithPasswordScreen.title')}
      </Title>
      <Subtitle className={styles.subtitle}>{t('loginWithPasswordScreen.subtitle')}</Subtitle>
      {!loginAction.isLoading && loginAction.isError && (
        <Summary className={styles.summaryWrapper} type={'error'}>
          {!accountIsDisabled && t('loginWithPasswordScreen.errorLoginInfo')}
          {accountIsDisabled && t('loginWithPasswordScreen.accountDisabled')}
        </Summary>
      )}
      {searchParams.has('resetPassword') && (
        <Summary className={styles.summaryWrapper} type={'success'}>
          {t('loginWithPasswordScreen.newPasswordInfo')}
        </Summary>
      )}
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        className={styles.formWrapper}
        autoComplete="off"
      >
        <Form.Item
          label={t('form.emailAddress')}
          name="email"
          rules={[
            { required: true, message: t('form.requiredValidation')! },
            {
              type: 'email',
              message: t('form.emailValidation')!,
            },
          ]}
        >
          <Input
            autoComplete="off"
            placeholder={t('form.emailPlaceholder')!}
            autoFocus={!isTouchDevice()}
          />
        </Form.Item>
        <Form.Item
          label={t('form.password')}
          name="password"
          rules={[{ required: true, message: t('form.requiredValidation')! }]}
        >
          <Input.Password
            autoComplete="off"
            placeholder={t('form.passwordPlaceholder')!}
            visibilityToggle={undefined}
          />
        </Form.Item>
        <Form.Item className={styles.buttonWrapper}>
          <Row justify="space-between" align="middle">
            <Col>
              <Button
                className={styles.backBtn}
                onClick={() => {
                  //Clear the resetPassword param as it is using for showing the message that reset password is success
                  setSearchParams((prev: any) => {
                    const { resetPassword, ...rest } = prev;

                    return rest;
                  });

                  // Wait for the url to be updated
                  setTimeout(() => {
                    navigate(LOGIN_ROUTES.SELECTION_SCREEN);
                  }, 0);
                }}
              >
                <Space>
                  <ArrowLeftIcon />
                  {t('form.back')}
                </Space>
              </Button>
            </Col>
            <Col>
              <Button
                loading={loginAction.isLoading}
                type="primary"
                htmlType="submit"
                className={styles.submitBtn}
              >
                {t('form.signIn')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <div className={styles.forgotPasswordWrapper}>
        <Trans
          t={t}
          i18nKey="loginWithPasswordScreen.forgetPasswordLink"
          components={[
            <Button
              className={styles.resetPassword}
              type="link"
              onClick={goToForgetPasswordScreen}
            />,
          ]}
        />
      </div>
    </div>
  );
}
