import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { getDomain } from '@/common/services';
import common_en from './en/common.json';
import common_sv from './sv/common.json';

const domain = getDomain(window.location.href);

use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['cookie', 'navigator'],
      cookieDomain: domain,
    },
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: 'en',
    supportedLngs: ['en', 'sv'],
    resources: {
      en: {
        common: common_en,
      },
      sv: { common: common_sv },
    },
    debug: process.env.NODE_ENV === 'development',
  });
