import { SpinSize } from 'antd/es/spin';
import styles from './style.module.scss';

type Props = {
  message?: string;
  size?: SpinSize;
  height?: number | string;
};

export function CenteredSpinner({ message, height = '100%' }: Props) {
  return (
    <div className={styles.wrapper} style={{ height }}>
      <div className={styles.loader} />
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
}
