export const PASSWORD_POLICY = {
  passwordRequireLowerletter: 'Password must contain a lower case letter',
  passwordRequireUpperletter: 'Password must contain an upper case letter',
  passwordRequireNumber: 'Password must contain a number',
  passwordRequireSymbol: 'Password must contain a special character or a space',
  passwordRequireNoLeadingOrTrailingSpaces: 'Password must not contain a leading or trailing space',
  passwordRequireLength: 'Password must contain at least 8 characters',
};

export function isLocalhost(url: string) {
  return url.includes('localhost') || url.includes('127.0.0.1');
}

export function checkPassword(password: string) {
  const passwordLength = 8;
  let passwordRequireLowerletter = false;
  let passwordRequireUpperletter = false;
  let passwordRequireNumber = false;
  let passwordRequireSymbol = false;
  let passwordRequireLength = false;
  let passwordRequireNoLeadingOrTrailingSpaces = false;

  if (password || password === '') {
    if (/[a-z]/.test(password)) {
      passwordRequireLowerletter = true;
    } else {
      passwordRequireLowerletter = false;
    }

    if (/[A-Z]/.test(password)) {
      passwordRequireUpperletter = true;
    } else {
      passwordRequireUpperletter = false;
    }

    if (
      /[-+=!$%^&*()_|~`{}\\[\]:\\/;<>?,.@#'"]|(?:\S)\s+(?=\S)/.test(password) ||
      password.indexOf('\\') >= 0
    ) {
      passwordRequireSymbol = true;
    } else {
      passwordRequireSymbol = false;
    }

    if (/[0-9]/.test(password)) {
      passwordRequireNumber = true;
    } else {
      passwordRequireNumber = false;
    }

    if (password.length < passwordLength) {
      passwordRequireLength = false;
    } else {
      passwordRequireLength = true;
    }
    if (/^\s+|\s+$/.test(password)) {
      passwordRequireNoLeadingOrTrailingSpaces = false;
    } else {
      passwordRequireNoLeadingOrTrailingSpaces = true;
    }
  }

  return {
    passwordRequireLowerletter,
    passwordRequireUpperletter,
    passwordRequireNumber,
    passwordRequireLength,
    passwordRequireSymbol,
    passwordRequireNoLeadingOrTrailingSpaces,
  };
}

export function partialHideEmail(email: string) {
  return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += '*';
    }
    return gp2;
  });
}

export function partialHidePhoneNumber(phoneNumber: string) {
  return phoneNumber.slice(0, 3) + phoneNumber.slice(3).replace(/.(?=...)/g, '*');
}
