import { useSearchParams, useNavigate } from 'react-router-dom';

export const useLoginNavigate = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (url: string, params?: Record<string, string>) => {
    if (params) {
      for (const key in params) {
        searchParams.append(key, params[key]);
      }
    }

    navigate({
      pathname: url,
      search: searchParams.toString(),
    });
  };
};
