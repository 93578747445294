import { Form, Input, Row, Col, Button, InputRef, Space } from 'antd';
import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Subtitle, Title } from '@/common/components/typography';
import { ArrowLeftIcon } from '@/common/components/icons';
import { sendCognitoOTP } from '@/common/services';
import { CenteredSpinner, Summary } from '@/common/components';
import { isTouchDevice } from '@/common/utils';
import { useLoginNavigate } from '@/common/components/login/hooks';
import { LOGIN_ROUTES } from '@/common/components/login/constants';
import styles from './styles.module.scss';

type Props = {
  onSubmit: (email: string, challengeName: string, session: string) => Promise<void>;
};

type FormValues = {
  email: string;
};

export default function LoginForm(props: Props) {
  const { t } = useTranslation('common');
  const navigate = useLoginNavigate();
  const { onSubmit } = props;
  const [form] = Form.useForm();
  const ref = useRef<InputRef>(null);

  const sendCodeAction = useMutation((values: FormValues) => sendCognitoOTP(values.email), {
    onSuccess(data, values) {
      onSubmit(values.email, data.challengeName, data.session);
    },
  });

  if (sendCodeAction.isLoading) {
    return <CenteredSpinner height={400} message={t('form.sendingCode')!} />;
  }

  return (
    <div>
      <Title className={styles.loginTitle} bold>
        {t('loginWithOneTimeCodeScreen.title')}
      </Title>
      <Subtitle className={styles.subtitle}>
        <Trans t={t} i18nKey="loginWithOneTimeCodeScreen.subtitle" components={[<i />]} />
      </Subtitle>
      {!sendCodeAction.isLoading && sendCodeAction.isError && (
        <Summary
          className={styles.summaryWrapper}
          type={sendCodeAction.isError ? 'error' : 'success'}
        >
          {t('loginWithOneTimeCodeScreen.errorEmailInfo')}
        </Summary>
      )}
      <Form
        form={form}
        onFinish={sendCodeAction.mutate}
        layout="vertical"
        requiredMark={false}
        className={styles.formWrapper}
      >
        <Form.Item
          label={t('form.emailAddress')}
          name="email"
          rules={[
            { required: true, message: t('form.requiredValidation')! },
            {
              type: 'email',
              message: t('form.emailValidation')!,
            },
          ]}
        >
          <Input ref={ref} placeholder={t('form.emailPlaceholder')!} autoFocus={!isTouchDevice()} />
        </Form.Item>
        <Form.Item className={styles.buttonWrapper}>
          <Row justify="space-between" align="middle">
            <Col>
              <Button
                className={styles.backBtn}
                onClick={() => {
                  navigate(LOGIN_ROUTES.SELECTION_SCREEN);
                }}
                disabled={sendCodeAction.isLoading}
              >
                <Space>
                  <ArrowLeftIcon />
                  {t('form.back')}
                </Space>
              </Button>
            </Col>
            <Col>
              <Button
                loading={sendCodeAction.isLoading}
                type="primary"
                htmlType="submit"
                className={styles.submitBtn}
              >
                {t('form.sendCode')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
}
