import { CenteredSpinner, Login } from '@common/components';
import { Result } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDidMount } from 'rooks';
import { useLoginQueryString } from '@/hooks';
import { isStandaloneWebApp } from '@/common/utils/pwa';
import { getAppConfigurations } from '@/common/utils';
import styles from './styles.module.scss';

const { environment, rootDomain } = getAppConfigurations();

export default function UniversalLoginPage() {
  const { t } = useTranslation('common');
  const [redirecting, setRedirecting] = useState(false);
  const { redirectToQuery, showOnlyOTP, showRegister } = useLoginQueryString();

  useDidMount(() => {
    if (isStandaloneWebApp()) {
      document.body.style.backgroundColor = '#095134';
    }

    if (environment !== 'local' && !redirectToQuery) {
      // Redirect to MyWisory if no param is set
      window.location.href = `https://my.${rootDomain}`;
    }
  });

  if (!redirectToQuery) {
    return <Result status={404} />;
  }

  if (redirecting) {
    return (
      <div className={styles.wrapper}>
        <CenteredSpinner message={t('form.navigating')!} size="large" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Login
        options={{
          allowRegister: showRegister,
          allowLoginInByOTP: true,
          allowLoginByPassword: !showOnlyOTP,
          allowLoginBySocial: !showOnlyOTP,
        }}
        onLoginSuccess={() => {
          if (redirectToQuery) {
            setRedirecting(true);
            window.location.href = redirectToQuery;
          }
        }}
      />
    </div>
  );
}
