import { Form, Input, Row, Col, Button, InputRef } from 'antd';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { api, isTouchDevice } from '@/common/utils';
import { isFormInvalid } from '@/common/utils/form';
import { Title, Subtitle, Summary, CenteredSpinner } from '@/common/components';
import styles from '../../styles.module.scss';

type Props = {
  onCancel: VoidFunction;
  onSuccess: (email: string) => void;
};

type FormValues = { emailAddress: string };

export default function ForgotPasswordEmailScreen({ onSuccess, onCancel }: Props) {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();
  const ref = useRef<InputRef>(null);
  const sendCodeAction = useMutation(
    (values: FormValues) =>
      api.baseAPI.userProfile.forgotPassword({ emailAddress: values.emailAddress }),
    {
      onSuccess(_, values) {
        onSuccess(values.emailAddress);
      },
    }
  );

  if (sendCodeAction.isLoading) {
    return <CenteredSpinner height={400} message={t('form.sendingCode')!} />;
  }

  return (
    <>
      <Title className={styles.loginTitle} bold>
        {t('resetPasswordScreen.title')}
      </Title>
      <Subtitle className={styles.subtitle}>{t('resetPasswordScreen.subtitle')}</Subtitle>
      {sendCodeAction.isError && !sendCodeAction.isLoading && (
        <Summary className={styles.summaryWrapper} type={'error'}>
          {t('resetPasswordScreen.errorEmailInfo')}
        </Summary>
      )}
      <Form
        form={form}
        onFinish={sendCodeAction.mutate}
        layout="vertical"
        requiredMark={false}
        className={styles.formWrapper}
      >
        <Form.Item
          label={t('form.emailAddress')}
          name="emailAddress"
          rules={[
            { required: true, message: t('form.requiredValidation')! },
            {
              type: 'email',
              message: t('form.emailValidation')!,
            },
          ]}
        >
          <Input ref={ref} placeholder={t('form.emailPlaceholder')!} autoFocus={!isTouchDevice()} />
        </Form.Item>
        <Form.Item className={styles.buttonWrapper} shouldUpdate>
          {() => (
            <Row justify="space-between" align="middle">
              <Col>
                <Button
                  className="wisory-btn-default"
                  disabled={sendCodeAction.isLoading}
                  onClick={onCancel}
                >
                  {t('form.cancel')}
                </Button>
              </Col>
              <Col>
                <Button
                  className="wisory-btn-default"
                  loading={sendCodeAction.isLoading}
                  type="primary"
                  htmlType="submit"
                  disabled={isFormInvalid(form)}
                >
                  {t('form.submit')}
                </Button>
              </Col>
            </Row>
          )}
        </Form.Item>
      </Form>
    </>
  );
}
