import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { APP_ROUTES } from '@constants';
import AuthProvider from '@context/auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthorizePage from '@/pages/authorize';
import UniversalLoginPage from '@/pages/universal-login';

const queryClient = new QueryClient();

function App() {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AuthProvider>
            <Routes>
              <Route path={APP_ROUTES.AUTHORIZE} element={<AuthorizePage />} />
              <Route path={APP_ROUTES.UNIVERSAL_LOGIN} element={<UniversalLoginPage />} />
            </Routes>
          </AuthProvider>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
