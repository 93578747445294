import { CenteredSpinner } from '@common/components';
import { useDidMount } from 'rooks';
import { Button, Result } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks';

import styles from './styles.module.scss';

export default function AuthorizePage() {
  const { t } = useTranslation('common');
  const { signInByAuthorizationCode, goToLoginPage } = useAuth();
  const [authorizeError, setAuthorizeError] = useState();
  const queryString = new URLSearchParams(window.location.search);
  const codeParam = queryString.get('code');
  const stateParam = queryString.get('state');

  useDidMount(async () => {
    if (codeParam && stateParam) {
      try {
        await signInByAuthorizationCode(codeParam);
        window.location.href = stateParam;
      } catch (error) {
        setAuthorizeError(error as any);
      }
    }
  });

  if (!codeParam || !stateParam) {
    return <Result title="The action is invalid" status={404} />;
  }

  if (authorizeError) {
    return (
      <Result
        title="The user is not existing in our system."
        status={404}
        extra={
          <Button
            type="primary"
            onClick={() => {
              goToLoginPage(stateParam);
            }}
          >
            Press here to login
          </Button>
        }
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <CenteredSpinner message={t('form.signing')!} size="large" />
    </div>
  );
}
