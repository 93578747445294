import classNames from 'classnames';
import ErrorSign from '../../../images/ErrorSign.svg';
import SuccessSign from '../../../images/SuccessSign.svg';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  type: 'success' | 'error';
};

const ICONS = {
  error: ErrorSign,
  success: SuccessSign,
};

export const Summary = ({ children, className = '', type }: React.PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.wrapper, styles[type], className)}>
      <div>
        <img src={ICONS[type]} alt="" />
      </div>
      <div>{children}</div>
    </div>
  );
};
