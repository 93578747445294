import { Link, useSearchParams } from 'react-router-dom';
import LogoImg from '../../../images/WisoryLogo.png';
import styles from './styles.module.scss';

export const Logo = () => {
  const [searchParams] = useSearchParams();

  return (
    <Link
      className={styles.logoWrapper}
      to={{
        pathname: '/',
        search: searchParams.toString(),
      }}
    >
      <img src={LogoImg} alt="" />
    </Link>
  );
};
