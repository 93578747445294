import { useState } from 'react';
import SlideRoutes from 'react-slide-routes';
import { Route } from 'react-router-dom';
import { LOGIN_ROUTES } from '../../constants';
import { useLoginNavigate } from '../../hooks';
import ForgotPasswordEmailScreen from './components/email-screen';
import ForgetPasswordCodeScreen from './components/code-screen';

type Props = {
  goBack: (props: { fromResetPasswordSuccess?: boolean }) => void;
};

export default function ForgetPasswordScreen({ goBack }: Props) {
  const [emailAddress, setEmailAddress] = useState('');
  const navigate = useLoginNavigate();

  return (
    <SlideRoutes duration={400}>
      <Route
        path="/"
        element={
          <ForgotPasswordEmailScreen
            onCancel={() => {
              goBack({ fromResetPasswordSuccess: false });
            }}
            onSuccess={(v) => {
              setEmailAddress(v);
              navigate('/forget-password/code');
            }}
          />
        }
      />
      <Route
        path="/code"
        element={
          <ForgetPasswordCodeScreen
            emailAddress={emailAddress}
            onCancel={() => {
              setEmailAddress('');
              navigate(LOGIN_ROUTES.PASSWORD_SCREEN);
            }}
            onSuccess={() => goBack({ fromResetPasswordSuccess: true })}
          />
        }
      />
    </SlideRoutes>
  );
}
