import { message } from 'antd';
import { useState } from 'react';
import { Route } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';
import { OTPForm } from '@/common/components';
import { sendCognitoOTP } from '@/common/services';
import { useAuth } from '@/hooks';
import { useLoginNavigate } from '../../hooks';
import { LOGIN_ROUTES } from '../../constants';
import LoginForm from './components/login-form';

type Props = {
  onLoginSuccess: VoidFunction;
};

export function LoginByOTPScreen({ onLoginSuccess }: Props) {
  const navigate = useLoginNavigate();
  const { signInByOTP } = useAuth();
  const [email, setEmail] = useState<string | undefined>();
  const [challengeName, setChallengeName] = useState<string | undefined>();
  const [session, setSession] = useState<string | undefined>();

  const sendCodeAction = () => {
    if (!email) {
      return Promise.reject();
    }

    return sendCognitoOTP(email).then((otpChallenge) => {
      setChallengeName(otpChallenge.challengeName);
      setSession(otpChallenge.session);
      message.info('OTP is sent to your mobile number.');
    });
  };

  return (
    <SlideRoutes duration={400}>
      <Route
        path="/"
        element={
          <LoginForm
            onSubmit={async (value, challengeName, session) => {
              setEmail(value);
              setChallengeName(challengeName);
              setSession(session);
              navigate(LOGIN_ROUTES.OTP_CODE_SCREEN);
            }}
          />
        }
      />
      <Route
        path="/code"
        element={
          <OTPForm
            emailAddress={email}
            sendOTP={sendCodeAction}
            verifyOTP={(otp) => {
              return signInByOTP(email!, session ?? '', challengeName ?? '', otp)
                .then((result) => {
                  if (!result.isSuccess) {
                    setSession(result.session);
                    throw result.errorMessage;
                  }
                })
                .then(onLoginSuccess);
            }}
          />
        }
      />
    </SlideRoutes>
  );
}
