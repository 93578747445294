export function useLoginQueryString() {
  const query = new URLSearchParams(window.location.search);
  const redirectToQuery = query.get('redirectTo') || '';
  const showOnlyOTP = query.has('showOnlyOTP');
  const showRegister = query.has('showRegister');

  return {
    redirectToQuery,
    showOnlyOTP,
    showRegister,
  };
}
