import { Form, Button, Row, Col, Space } from 'antd';
import OtpInput from 'react-otp-input';
import { useRef, useState } from 'react';
import { useDidMount, useIntervalWhen } from 'rooks';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Summary } from '@/common/components';
import { CenteredSpinner } from '@/common/components/centered-spinner';
import { Subtitle, Title } from '@/common/components/typography';
import { useLoginNavigate } from '@/common/components/login/hooks';
import { LOGIN_ROUTES } from '@/common/components/login/constants';
import { LOGIN_ERROR_CODE } from '@/constants';
import { ArrowLeftIcon } from '../../../../../icons';
import styles from './styles.module.scss';
type FormValues = { otp: string };

type Props = {
  emailAddress?: string;
  sendOTP(): Promise<void>;
  verifyOTP: (otp: string) => Promise<any>;
};

const DEFAULT_TIMER = 60;

export function OTPForm({ emailAddress, sendOTP, verifyOTP }: Props) {
  const { t } = useTranslation('common');
  const navigate = useLoginNavigate();
  const [form] = Form.useForm<FormValues>();
  const [startTimer, setStartTimer] = useState(true);
  const [timer, setTimer] = useState(DEFAULT_TIMER);
  const sendCodeAction = useMutation(() => sendOTP(), {
    onSuccess() {
      isFirstLoadRef.current = true;
      setTimer(DEFAULT_TIMER);
      setStartTimer(true);
    },
  });
  const submitCodeAction = useMutation<any, { errorCode: string }, any, any>((values: FormValues) =>
    verifyOTP(values.otp)
  );
  const isFirstLoadRef = useRef(false);

  const onResendOtp = () => {
    sendCodeAction.mutate();
  };

  useIntervalWhen(
    () => {
      const newTimer = timer === 0 ? 0 : timer - 1;
      setTimer(newTimer);
    },
    1000, // run callback every 1 second
    startTimer, // start the timer when it's true
    true // no need to wait for the first interval
  );

  useDidMount(() => {
    if (!emailAddress) {
      navigate(LOGIN_ROUTES.OTP_SCREEN);
      return;
    }
  });

  if (!emailAddress) {
    return null;
  }

  if (sendCodeAction.isLoading && !isFirstLoadRef.current) {
    return <CenteredSpinner height={400} message={t('form.sendingCode')!} />;
  }

  const accountIsDisabled =
    submitCodeAction.error &&
    submitCodeAction.error?.errorCode === LOGIN_ERROR_CODE.USER_IS_DISABLED;

  return (
    <div>
      <Title className={styles.loginTitle} bold>
        {t('loginWithOneTimeCodeScreen.title')}
      </Title>
      <Subtitle className={styles.loginSubtitle}>
        {t('loginWithOneTimeCodeScreen.subtitle2')}
      </Subtitle>
      {!sendCodeAction.isLoading && !submitCodeAction.isLoading && (
        <Summary
          className={styles.summaryWrapper}
          type={submitCodeAction.isError || sendCodeAction.isError ? 'error' : 'success'}
        >
          {sendCodeAction.isError && t('loginWithOneTimeCodeScreen.errorEmailInfo')}
          {!submitCodeAction.isError && t('loginWithOneTimeCodeScreen.successInfo')}
          {submitCodeAction.isError &&
            !accountIsDisabled &&
            t('loginWithOneTimeCodeScreen.errorCodeInfo')}
          {submitCodeAction.isError &&
            accountIsDisabled &&
            t('loginWithOneTimeCodeScreen.accountDisabled')}
        </Summary>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={submitCodeAction.mutate}
        autoComplete="off"
        validateTrigger="onSubmit"
      >
        <Form.Item
          name="otp"
          rules={[
            { required: true, message: t('form.requiredValidation')! },
            { min: 6, message: t('form.codeValidation')! },
          ]}
          className={styles.otpWrapper}
        >
          {/**@ts-ignore */}
          <OtpInput
            numInputs={6}
            renderSeparator={null}
            renderInput={(props, index) => {
              return (
                <input
                  {...props}
                  autoComplete="one-time-code"
                  type="tel"
                  inputMode="decimal"
                  pattern="\d*"
                  className={classNames(styles.otpInput, {
                    [styles.hasValue]: props.value,
                    [styles.lastItem]: index === 5,
                  })}
                />
              );
            }}
            shouldAutoFocus
          />
        </Form.Item>
        <Form.Item className={styles.buttonWrapper}>
          <Row justify="space-between" align="middle">
            <Col>
              <Button
                className={styles.backBtn}
                onClick={() => {
                  navigate(LOGIN_ROUTES.OTP_SCREEN);
                }}
                disabled={submitCodeAction.isLoading}
              >
                <Space>
                  <ArrowLeftIcon />
                  {t('form.back')}
                </Space>
              </Button>
            </Col>
            <Col>
              <Button
                className={styles.submitBtn}
                loading={submitCodeAction.isLoading}
                type="primary"
                htmlType="submit"
              >
                {t('form.verify')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <div className={styles.resendWrapper}>
        <Space direction="vertical" size="small">
          <div>{t('loginWithOneTimeCodeScreen.didNotGetMessage')}</div>
          {timer > 0 && (
            <div>{t('loginWithOneTimeCodeScreen.resendLinkLoadingState', { seconds: timer })}</div>
          )}

          {timer === 0 && (
            <Button type="link" onClick={onResendOtp} className={styles.resendBtn}>
              {t('loginWithOneTimeCodeScreen.resendLinkReadyState')}
            </Button>
          )}
        </Space>
      </div>
    </div>
  );
}
