import React, { useState } from 'react';
import {
  getUserSession,
  getUserInfo,
  setToken,
  getToken,
  removeToken,
  loginByPassword,
  getSocialLoginUrl,
  getForgotPasswordUrl,
  verifyCognitoOTP,
} from '@common/services';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '@/constants';
import { useLoginQueryString } from '@/hooks';

export interface AuthContextType {
  user: API.UserInfoModel | undefined;
  isSigning: boolean;
  signOut: () => void;
  setUser: (user: API.UserInfoModel) => void;
  goToLoginPage: (redirectToUrl: string) => void;
  goToGoogleLogin: () => void;
  goToForgetPassword: () => void;
  tryToLogin: () => Promise<void>;
  signInByOTP: (
    email: string,
    session: string,
    challengeName: string,
    otp: string
  ) => Promise<API.UserSessionResult>;
  signInByPassword: (email: string, password: string) => Promise<void>;
  signInByAuthorizationCode: (code: string) => Promise<void>;
  getProfile: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { redirectToQuery } = useLoginQueryString();
  const [user, setUser] = useState<API.UserInfoModel | undefined>(undefined);
  const [isSigning, setIsSigning] = useState(false);

  const getProfile = async () => {
    const userInfo = await getUserInfo();
    setUser(userInfo);
  };

  const goToLoginPage = (redirectTo: string) => {
    navigate(`${APP_ROUTES.UNIVERSAL_LOGIN}?redirectTo=${redirectTo}`);
  };

  const goToGoogleLogin = () => {
    window.location.href = getSocialLoginUrl(redirectToQuery);
  };

  const goToForgetPassword = () => {
    window.location.href = getForgotPasswordUrl(redirectToQuery);
  };

  const signInByOTP = async (
    email: string,
    session: string,
    challengeName: string,
    code: string
  ) => {
    try {
      const token = await verifyCognitoOTP(email, session, challengeName, code);
      setToken(token);
      return token;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const signInByPassword = async (email: string, password: string) => {
    try {
      const token = await loginByPassword(email, password);
      setToken(token);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const signInByAuthorizationCode = async (code: string) => {
    try {
      const token = await getUserSession(code);
      setToken(token);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const tryToLogin = async () => {
    setIsSigning(true);

    const userSession = getToken();

    if (userSession) {
      await getProfile();
      setIsSigning(false);
    }
  };

  const signOut = () => {
    setUser(undefined);
    removeToken();
  };

  const value = {
    user,
    isSigning,
    setUser,
    tryToLogin,
    signInByAuthorizationCode,
    signInByOTP,
    signInByPassword,
    goToLoginPage,
    goToGoogleLogin,
    goToForgetPassword,
    signOut,
    getProfile,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
