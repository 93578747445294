import { Form, Input, Row, Col, Button, InputRef } from 'antd';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useDidMount } from 'rooks';
import { api, isTouchDevice } from '@/common/utils';
import {
  confirmPasswordValidator,
  isFormInvalid,
  newPasswordValidator,
  passwordValidator,
} from '@/common/utils/form';
import { Subtitle, Summary, Title } from '@/common/components';
import { LOGIN_ROUTES } from '@/common/components/login/constants';
import { useLoginNavigate } from '@/common/components/login/hooks';
import styles from '../../styles.module.scss';

type Props = {
  emailAddress: string;
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
};

type FormValues = {
  verificationCode: string;
  newPassword: string;
  confirmNewPassword: string;
};

export default function ForgetPasswordCodeScreen({ emailAddress, onSuccess, onCancel }: Props) {
  const { t } = useTranslation('common');
  const navigate = useLoginNavigate();
  const [form] = Form.useForm<FormValues>();
  const ref = useRef<InputRef>(null);
  const resetAction = useMutation(
    (values: FormValues) =>
      api.baseAPI.userProfile.resetUserPassword(
        {
          emailAddress,
          newPassword: values.newPassword,
          confirmationCode: values.verificationCode,
        },
        // @ts-ignore
        { skipGlobalErrorMessage: true }
      ),
    {
      onSuccess,
    }
  );

  useDidMount(() => {
    if (!emailAddress) {
      navigate(LOGIN_ROUTES.FORGET_PASSWORD_SCREEN);
      return;
    }
  });

  if (!emailAddress) {
    return null;
  }

  return (
    <>
      <Title className={styles.loginTitle} bold>
        {t('resetPasswordScreen.title')}
      </Title>
      <Subtitle className={styles.subtitle}>{t('resetPasswordScreen.subtitle2')}</Subtitle>
      {resetAction.isError && !resetAction.isLoading && (
        <Summary className={styles.summaryWrapper} type={'error'}>
          {t('resetPasswordScreen.errorCodeInfo')}
        </Summary>
      )}
      <Form
        form={form}
        onFinish={resetAction.mutate}
        layout="vertical"
        requiredMark={false}
        className={styles.formWrapper}
      >
        <Form.Item
          label={t('form.code')}
          name="verificationCode"
          rules={[{ required: true, message: t('form.requiredValidation')! }]}
        >
          <Input ref={ref} placeholder={t('form.codePlaceholder')!} autoFocus={!isTouchDevice()} />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label={t('form.newPassword')}
          rules={[
            {
              required: true,
              message: t('form.requiredValidation')!,
            },
            {
              validator: (_, value) => passwordValidator(value),
            },
            ({ getFieldValue }) => ({
              validator: (_, newPassword) =>
                newPasswordValidator(getFieldValue('oldPassword'), newPassword),
            }),
          ]}
        >
          <Input.Password placeholder={t('form.newPasswordPlaceholder')!} />
        </Form.Item>
        <Form.Item
          name="confirmNewPassword"
          label={t('form.confirmPassword')}
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: t('form.requiredValidation')!,
            },
            ({ getFieldValue }) => ({
              validator: (_, confirmPassword) =>
                confirmPasswordValidator(
                  getFieldValue('newPassword'),
                  confirmPassword,
                  t('form.confirmPasswordNotMatch')
                ),
            }),
          ]}
        >
          <Input.Password placeholder={t('form.confirmPasswordPlaceholder')!} />
        </Form.Item>
        <Form.Item className={styles.buttonWrapper} shouldUpdate>
          {() => (
            <Row justify="space-between" align="middle">
              <Col>
                <Button
                  className="wisory-btn-default"
                  disabled={resetAction.isLoading}
                  onClick={onCancel}
                >
                  {t('form.cancel')}
                </Button>
              </Col>
              <Col>
                <Button
                  className="wisory-btn-default"
                  loading={resetAction.isLoading}
                  type="primary"
                  htmlType="submit"
                  disabled={isFormInvalid(form)}
                >
                  {t('form.submit')}
                </Button>
              </Col>
            </Row>
          )}
        </Form.Item>
      </Form>
    </>
  );
}
