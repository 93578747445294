import classNames from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
  bold?: boolean;
  size?: 'small' | 'default' | 'large' | 'x-large';
  color?: 'green' | 'white';
  style?: CSSProperties;
};

export function Title({
  noMarginTop,
  noMarginBottom,
  className = '',
  size = 'default',
  bold,
  style = {},
  children,
}: PropsWithChildren<Props>) {
  return (
    <h2
      className={classNames(
        styles.title,
        styles[size],
        {
          [styles.titleMarginTop]: !noMarginTop,
          [styles.titleMarginBottom]: !noMarginBottom,
          [styles.bold]: bold,
        },
        className
      )}
      style={{
        fontFamily: bold ? 'var(--font-sans)' : 'var(--font-serif)',
        color: size === 'x-large' ? 'var(--white)' : 'var(--green-3)',
        ...style,
      }}
    >
      {children}
    </h2>
  );
}

export function Subtitle({
  noMarginTop,
  noMarginBottom,
  className = '',
  children,
  size = 'default',
}: PropsWithChildren<Props>) {
  return (
    <div
      className={classNames(
        styles.subtitle,
        styles[size],
        {
          [styles.subtitleMarginTop]: !noMarginTop,
          [styles.subtitleMarginBottom]: !noMarginBottom,
        },
        className
      )}
    >
      {children}
    </div>
  );
}

export function Label({ children }: PropsWithChildren<{}>) {
  return <p className={styles.label}>{children}</p>;
}
