import { FormInstance } from 'antd';
import { Dayjs } from 'dayjs';
import { PasswordStrength } from '../components';
import { checkPassword } from './string';

export function isFormInvalid(form: FormInstance<any>) {
  return (
    !form.isFieldsTouched() ||
    form.getFieldsError().filter(({ errors }) => errors.length).length > 0
  );
}

export function newPasswordValidator(oldPassword: string, newPassword: string) {
  if (oldPassword && newPassword && oldPassword === newPassword) {
    return Promise.reject('New password cannot be the same as old password.');
  }

  return Promise.resolve();
}

export function passwordValidator(value: string) {
  if (!value) {
    return Promise.resolve();
  }

  const rules = checkPassword(value);

  const isError = Object.keys(rules).some((k) => !rules[k as keyof typeof rules]);

  if (!isError) {
    return Promise.resolve();
  } else {
    return Promise.reject(<PasswordStrength value={value} />);
  }
}

export function confirmPasswordValidator(
  password: string,
  confirmPassword: string,
  message: string
) {
  if (!password || !confirmPassword || confirmPassword === password) {
    return Promise.resolve();
  }

  return Promise.reject(new Error(message));
}

export function dateRangeValidator(startTime: Dayjs, endTime: Dayjs) {
  if (!startTime || endTime.isAfter(startTime)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('End time should be greater than start time'));
}
